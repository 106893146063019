.invite-form {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	gap: 0.5rem;
}

.invite-form-input {
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 0.375rem;
	font-size: 1.25rem;
	font-weight: 500;
	padding: 0.75rem 1.5rem;
	text-color: #000;
	width: 100%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.invite-form-input:focus {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	outline: none;
}

.invite-form-button {
	background-color: #000;
	border: 2px solid #000;
	border-radius: 0.375rem;
	border-color: #fff;
	color: #fff;
	cursor: pointer;
	font-size: 1.25rem;
	font-weight: 500;
	padding: 0.75rem 1.5rem;
	text-align: center;
	transition: background-color 0.3s ease;
	width: 100%;
	margin-left: 50px;
}

.invite-form-button:hover {
	background-color: #808080;
}

.invite-form-button:focus {
	outline: none;
}

@media (min-width: 640px) {
	.invite-form-input,
	.invite-form-button {
		width: auto;
	}
}
