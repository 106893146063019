.hero-image {
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 22.875rem;
	max-width: 100%;
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  
  .hero-image-bg {
	fill: #4B5563;
  }
  
  .hero-image-foreground {
	fill: #343E4E;
  }
  